import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { debounce } from 'lodash';
import { ReactComponent as BoxCloseSvg } from '../../assets/icon/box-close.svg';
import { ReactComponent as ButtonPlusSvg } from '../../assets/icon/button-plus.svg';

import { ulazniRacuniSelector } from '../../store/selectors/UlazniRacuniSelector';
import {
  resetNoviRacun,
} from '../../store/actions/RacuniActions';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Link, useLocation} from 'react-router-dom';
import { ULAZNI_RACUNI } from '../../constants/routes';

import Moment from 'react-moment';
import 'moment/locale/me';

import GridLoader from 'react-spinners/GridLoader';
import { spinnerStyleGrid } from '../../constants/spinner';
import { depozitWithdrawService } from '../../services/DepozitWithdrawService';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UlazniRacuniTable from './UlazniRacuniTable';
import { getUlazniRacuni } from '../../store/actions/UlazniRacuniActions';
import ReactDOM from "react-dom";
import Modal from "../shared/forms/Modal";

toast.configure();

const toastSettings = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const options = [
  { value: null, label: 'Prikaži Sve' },
  { value: 'placen', label: 'Plaćen' },
  { value: 'nenaplativ', label: 'Nenaplativ' },
  { value: 'nijeplacen', label: 'Nije Plaćen' },
];

const searchParams = {};

const searchDebounced = debounce((callback) => callback(), 200);

const UlazniRacuni = () => {
  const dispatch = useDispatch();
  const ulazniRacuni = useSelector(ulazniRacuniSelector());
  const query = useLocation().search;
  const page = new URLSearchParams(query).get('page');

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');

  const [statusVisible, setStatusVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const [dateStartVisible, setDateStartVisible] = useState(false);
  const [dateEndVisible, setDateEndVisible] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    dispatch(getUlazniRacuni({ page }));
  }, [dispatch]);

  const handleSearch = (value) => {
    let filtered = value?.search?.replace(/[^0-9a-zA-Zžćšđč -]/gi, '');
    searchParams.search = filtered;
    dispatch(getUlazniRacuni({...searchParams}));
  };

  const resetDatePicker = () => {
    searchParams.startDate = null;
    searchParams.endDate = null;
    setStartDate(null);
    setEndDate(null);
    handleSearch(searchParams);
    setDateStartVisible(false);
    setDateEndVisible(false);
  };

  const resetSearch = () => {
    searchParams.search = null;
    setSearch('');
    handleSearch(searchParams);
    setSearchVisible(false);
  };

  const resetStatus = () => {
    searchParams.status = null;
    setStatus('');
    handleSearch(searchParams);
    setStatusVisible(false);
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
    setSearchVisible(true);
    const value = event.target.value;
    searchParams.search = value;
    searchDebounced(() => handleSearch(searchParams));

    if (! event.target.value) {
      setSearchVisible(false);
    }
  };

  const handleStatusChange = (selectedStatusOption) => {
    setStatus(selectedStatusOption.label);
    setStatusVisible(true);
    searchParams.status = selectedStatusOption.value;
    handleSearch(searchParams);
  };

  const handleStartDateChange = (date) => {
    setDateStartVisible(true);
    searchParams.startDate = date;
    setStartDate(date);
    handleSearch(searchParams);
  };

  const handleEndDateChange = (date) => {
    setDateEndVisible(true);
    searchParams.endDate = date;
    setEndDate(date);
    handleSearch(searchParams);
  };

  const selectStyle = {
    control: (styles) => ({
      ...styles,
      backgroundColor: '#F3F4F6',
      borderRadius: 4,
      height: '45px',
      minHeight: 'unset',
    }),
  };

  const [showModal, setShowModal] = useState(false);
  const [showModalWithdraw, setShowModalWithdraw] = useState(false);
  const [depozitLoaded, setDepozitLoaded] = useState(false);
  // const [withdrawLoaded, setWithdrawLoaded] = useState(false);
  const [withdrawError, setWithdrawError] = useState(false);
  const [withdraw, setWithdraw] = useState(0);

  const handleDepositLoaded = (props) => {
    setDepozitLoaded(props);
  };

  const closeModal = (props) => {
    setShowModal(props);
  };

  const hideModal = (props) => {
    setShowModalWithdraw(props);
  };

  const handlePodizanjeDepozita = () => {
    depozitWithdrawService
      .storeDepozitWithdraw({
        iznos_withdraw: withdraw,
      })
      .then((data) => {
        // setWithdrawLoaded(data);
      })
      .catch((error) => {
        toast.error(
          'Nije moguće prijaviti podizanje gotovine: ' +
            error.response.data.message,
          toastSettings
        );
        setWithdrawError(error);
      });
  };

  useEffect(() => {
    //Race Condition and Preventing Memory Leaks
    let isActive = true;

    depozitWithdrawService
      .getDepozitToday()
      .then((data) => {
        if (isActive && data.data.length !== 0) {
          setDepozitLoaded(true);
        }
      })
      .catch((err) =>
        toast.error('Nije moguće učitati vrijednost depozita!', toastSettings)
      );
    return () => {
      isActive = false;
    };
  }, [showModalWithdraw, showModal]);

  function openModal() {
    const node = document.getElementById("custom-prompt");

    const cleanUp = (answer) => {
      ReactDOM.unmountComponentAtNode(node);
    };

    const confirm = () => cleanUp(true);
    const cancel = () => cleanUp(false);

    let date = new Date();

    ReactDOM.render(
        <Modal
            title="Ulazni računi uključeni"
            message="Mogućnost dodavanja ulaznih računa je uključena i biće Vam uskoro dostupna."
            onConfirm={confirm}
            onCancel={cancel}
            confirmText="Zatvori"
        />
        , node
    );
  }

  return (
    <>
      <div className="title jc-sb mob-fd-column mob-ai-start">
        <h1 className="heading-primary">Prijem računa / Troškovi <span className="tag tag__neutral-border ml-md-4 vm" title="Opcija dostupna korisnicima Start paketa">Start</span></h1>

        <div className="df w-50 jc-end mob-w-100 mob-fd-column">
        {/*<Link exact to={ULAZNI_RACUNI.CREATE}>*/}
          <button className="btn btn__primary" onClick={openModal}>
            <ButtonPlusSvg />
            Novi ulazni račun
          </button>
        {/*</Link>*/}
        </div>
      </div>
      <div className="main-content__box">
        <div className="content" style={{ width: '100%' }}>
          <div className="main-content__search-wrapper df">
            <div className="df jc-sb w-100 tabp-flex-wrap mob-fd-column">
              {/* <div className="search df ai-c w-53"> */}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="search df ai-c w-45 mob-w-100 mr-15-tabp-0 tabp-w-49"
              >
                <button className="search__button" type="button"></button>
                <input
                  name="search"
                  placeholder="Pretraži Račune"
                  className="search__input"
                  value={search}
                  onChange={handleChange}
                />
              </form>
              {/* </div> */}
              <Select
                options={options}
                onChange={handleStatusChange}
                styles={selectStyle}
                value={status ? { label: status } : options[0]}
                defaultValue={options[0]}
                className="mob-w-100 w-20 mob-mt-10 mr-15-tabp-0 tabp-w-49"
              />
              {/* <div className="select w-25 df"> */}
              <div className="df jc-sb tabp-w-100">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => handleStartDateChange(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="date-select mob-w-100 mob-mt-10"
                  placeholderText="Datum od:"
                />
                <DatePicker
                  selected={endDate}
                  onChange={(date) => {
                    let actualDate = new Date(date);
                    let endOfDayDate = new Date(actualDate.getFullYear()
                        ,actualDate.getMonth()
                        ,actualDate.getDate()
                        ,23,59,59);
                    handleEndDateChange(endOfDayDate);
                  }}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="date-select mob-w-100 mob-mt-10"
                  placeholderText="Datum do:"
                />
              </div>
              {/* </div> */}
            </div>
            <ul className="box-wrapper">
              <li className="box visible ml-0">
                <p className="txt-light">Ukupan iznos na prikazanim računima</p>
                <h3 className="heading-tertiary">
                  {ulazniRacuni?.ukupna_cijena !== undefined
                    ? ulazniRacuni?.ukupna_cijena?.toFixed(2).replace('.', ',') + '€'
                    : '0,00€'}
                </h3>
              </li>

              {searchVisible && (
                <li className={searchVisible ? 'box visible' : 'box'}>
                  <p className="txt-light">Pretraga</p>
                  <h3 className="heading-tertiary">{search}</h3>
                  <span onClick={resetSearch} className="box__close">
                    <BoxCloseSvg />
                  </span>
                </li>
              )}

              {statusVisible && (
                <li className={statusVisible ? 'box visible' : 'box'}>
                  <p className="txt-light">Status</p>
                  <h3 className="heading-tertiary">{status}</h3>
                  <span onClick={resetStatus} className="box__close">
                    <BoxCloseSvg />
                  </span>
                </li>
              )}

              {dateStartVisible || dateEndVisible ? (
                <li
                  className={
                    dateStartVisible || dateEndVisible ? 'box visible' : 'box'
                  }
                >
                  <p className="txt-light">Datum</p>
                  <h3 className="heading-tertiary">
                    {startDate && (
                      <Moment locale="me" format="DD. MMM YYYY.">
                        {startDate}
                      </Moment>
                    )}
                    -
                    {endDate && (
                      <Moment locale="me" format="DD. MMM YYYY.">
                        {endDate}
                      </Moment>
                    )}
                  </h3>
                  <span onClick={resetDatePicker} className="box__close">
                    <BoxCloseSvg />
                  </span>
                </li>
              ) : null}
            </ul>
          </div>
          <div>
            {/* {ulazniRacuni &&
            ulazniRacuni.data &&
            ulazniRacuni.data.length === 0 &&
            !ulazniRacuni.path ? (
              <GridLoader css={spinnerStyleGrid} size={15} />
            ) : ulazniRacuni && ulazniRacuni.data && ulazniRacuni.data.length === 0 ? (
              <div className="msg-center">
                <p> {'Nema sadržaja u listi'}</p>
              </div>
            ) : (
              <UlazniRacuniTable ulazniRacuni={ulazniRacuni} searchParams={searchParams} page={page} />
            )} */}
            <div className="msg-center">
                <p> {'Nema sadržaja u listi'}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UlazniRacuni;
