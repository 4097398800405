import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ERRORS, ULAZNI_RACUNI } from '../constants/routes';
import UlazniRacuni from '../components/UlazniRacuni/UlazniRacuni';
import Bezgotovinski from '../components/Racuni/Bezgotovinski/Bezgotovinski';
import BezgotovinskiPreview from '../components/Racuni/Bezgotovinski/BezgotovinskiPreview';
import BezgotovinskiEdit from '../components/Racuni/Bezgotovinski/BezgotovinskiEdit';

const UlazniRacuniRouter = () => {
  return (
    <Switch>
      {/*<Route exact path={ULAZNI_RACUNI.EDIT}>*/}
      {/*  <BezgotovinskiEdit tip="ulazniRacun" />*/}
      {/*</Route>*/}
      {/*<Route exact path={ULAZNI_RACUNI.CREATE}>*/}
      {/*  <Bezgotovinski />*/}
      {/*</Route>*/}
      <Route path={ULAZNI_RACUNI.SHOW}>
        <BezgotovinskiPreview tip="ulazniRacun" />
      </Route>
      <Route exact path={ULAZNI_RACUNI.INDEX}>
        <UlazniRacuni />
      </Route>
      <Redirect to={ERRORS.NOT_FOUND} />
    </Switch>
  );
};

export default UlazniRacuniRouter;
